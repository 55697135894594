/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// React
import React, { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactDOM from "react-dom";
import axios from 'axios';

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardImg,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  ListGroupItem,
  ListGroup,
  Progress,
  Container,
  Row,
  Col,
  Modal,
  Media,
  Table
} from "reactstrap";

// core components
import OffersHeader from "components/Headers/OffersHeader.js";

import {
  UPDATE_PROCESS_STAGE
} from 'store/actions';

// Config
import config from "config";

function Review({ profile, etl, dispatch }) {
  // States
  const listRef = React.useRef(null);
  const [report, setReport] = React.useState("offer_coverage");
  const [statindex, setStatindex] = React.useState(0);

  // Send email confirmation
  const [sendModal, setSendModal] = React.useState(false);

  // Get history
  const history = useHistory();

  return (
    <>
      <OffersHeader
        stage="review"
        title="Review Offers"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
      />
      <Container id="profile" className="mt--6" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Review Processed Offers</h3>
                  </Col>
                  <Col xs="4">

                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <FormGroup className="row">
                    <Col md="4">
                      <ListGroup>
                        { etl.job.stats && etl.job.stats.map((stat, index) => (
                          <ListGroupItem
                            className="list-group-item-action flex-column align-items-start py-4 px-4"
                            href=""
                            onClick={(e) => {
                              e.preventDefault();
                              setReport(stat.name);
                              setStatindex(stat.index);
                            }}
                            active={report == stat.name}
                            key={index}
                          >
                            <p className="text-sm mb-0">
                              <span className="text-info bold">{stat.display}</span>
                            </p>
                          </ListGroupItem>
                        ))}
                      </ListGroup>
                    </Col>
                    <Col md="8">
                      <Card className="bg-default shadow" style={{
                        height: 350
                      }}>
                        <CardHeader className="bg-transparent border-0">
                          <h3 className="text-white mb-0">Dynamic Rewards</h3>
                        </CardHeader>
                        <div className="table-responsive" ref={listRef}>
                          <Table className="align-items-center table-dark table-flush">
                            <thead className="thead-dark">
                              <tr>
                                { etl.job.stats && etl.job.stats.length > 0 && etl.job.stats[statindex].headers.map((header, index) => (
                                  <th className="sort" data-sort={header.name} scope="col" key={index}>
                                    {header.display}
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody className="list">
                              { etl.job.stats && etl.job.stats.length > 0 && etl.job.stats[statindex].data.map((row, index) => (
                                <tr key={index}>
                                  <td className={etl.job.stats[statindex].headers[0].name}>{row[etl.job.stats[statindex].headers[0].name]}</td>
                                  <td className={etl.job.stats[statindex].headers[1].name}>{row[etl.job.stats[statindex].headers[1].name]}</td>
                                  <td className={etl.job.stats[statindex].headers[2].name}>{row[etl.job.stats[statindex].headers[2].name]}</td>
                                  <td className={etl.job.stats[statindex].headers[3].name}>{row[etl.job.stats[statindex].headers[3].name]}</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                        <CardFooter className="bg-default shadow">
                        </CardFooter>
                      </Card>
                      <Row>
                        <Col md="4">
                          { etl.job.export.sources &&
                            <Button block color="secondary" size="lg"
                              onClick={() => {
                                if(etl.job && etl.job.export.sources && etl.job.export.sources != "") {
                                  window.location.href = etl.job.export.sources;
                                }
                              }}
                            >
                                <span className="btn-inner--icon mr-2">
                                  <i className="ni ni-cloud-download-95" />
                                </span>
                                <span className="btn-inner--text">Source Files</span>
                            </Button>
                          }
                        </Col>
                        <Col md="4">
                          { (etl.job.export.offers && etl.job.export.offers.status) ?
                            {
                              'Processed': <Button block color="default" size="lg"
                                        onClick={() => {
                                          if(etl.job && etl.job.export.offers && etl.job.export.offers.files && etl.job.export.offers != "") {
                                            window.location.href = etl.job.export.offers.files;
                                          }
                                        }}
                                      >
                                          <span className="btn-inner--icon mr-2">
                                            <i className="ni ni-cloud-download-95" />
                                          </span>
                                          <span className="btn-inner--text">Campaign Files</span>
                                      </Button>,
                              'Ready': <Button block size="lg"
                                          onClick={() => {
                                            // Create document ID
                                            const documentId = `${config.client.id}-${etl.job.date}`.replaceAll('-', '_')

                                            // Send confirmation
                                            console.log(` ->Processing Offers: ${documentId}`);

                                            axios.post(`${config.etl.api.process.url}`, {
                                              document_id: documentId
                                            });

                                            // Got to ETL list page
                                            history.push("/admin/offers/etl");
                                          }}
                                          color="primary"
                                        >
                                            <span className="btn-inner--icon mr-2">
                                              <i className="ni ni-money-coins" />
                                            </span>
                                            <span className="btn-inner--text">Process Offers</span>
                                        </Button>
                            }[etl.job.export.offers.status]
                          :
                          <>
                          </>
                          }
                        </Col>
                        <Col md="4">
                          { (etl.job.export.emails && etl.job.export.offers.status && etl.job.export.offers.status == 'Processed') ?
                            {
                              'Sent': <Button block size="lg"
                                          onClick={() => {
                                            // TBD
                                          }}
                                          color="success"
                                          disabled={true}
                                        >
                                            <span className="btn-inner--icon mr-2">
                                              <i className="ni ni-check-bold" />
                                            </span>
                                            <span className="btn-inner--text">Emails Sent</span>
                                        </Button>,
                              'Not Sent': <Button block size="lg"
                                          onClick={() => {
                                            // Load send confirmation modal
                                            setSendModal(true);
                                          }}
                                          color="primary"
                                        >
                                            <span className="btn-inner--icon mr-2">
                                              <i className="ni ni-email-83" />
                                            </span>
                                            <span className="btn-inner--text">Send Emails</span>
                                        </Button>
                            }[etl.job.export.emails.status]
                          :
                          <>
                          </>
                        }
                        </Col>
                      </Row>
                    </Col>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        className="modal-dialog-centered"
        isOpen={sendModal}
        toggle={() => setSendModal(false)}
        size="lg"
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Confirm Campaign Email Send
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setSendModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Form>
            <div className="pl-lg-4">
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="delete-confirm"
                    >
                      Please Type SEND to Proceed
                    </label>
                    <Input
                      id="send-confirm"
                      placeholder=""
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            href=""
            onClick={() => {
              // Update contact
              console.log("Sending Campaign Emails");

              // Get value of form
              const sendConfirmation = document.getElementById('send-confirm').value;

              // Verify if delete exists
              if(sendConfirmation === "SEND") {
                // Create document ID
                const documentId = `${config.client.id}-${etl.job.date}`.replaceAll('-', '_')

                // Send confirmation
                console.log(` -> Sending Emails: ${documentId}`);

                axios.post(`${config.etl.api.notification.url}`, {
                  document_id: documentId
                });

                // Got to ETL list page
                history.push("/admin/offers/etl");
              }
            }}
            size="xl"
          >
            Confirm Email Send
          </Button>
        </div>
      </Modal>
    </>
  );
}

// Connect to store
const ConnectedReview = connect(state => ({
  profile: state.profile,
  etl: state.etl
}))(Review);

export default ConnectedReview;
