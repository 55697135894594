/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Account config
import config from "config";

import {
  SET_ETL_JOB,
  UPDATE_PROCESS_STAGE
} from 'store/actions';

function OffersHeader({ profile, title, description, stage, action, dispatch }) {
  // Get history
  const history = useHistory();

  return (
    <>
      <div
        className="header pb-6 d-flex align-items-center"
        style={{
          minHeight: "300px",
          backgroundImage: `url(${config.assets.img.brand.backgrounds.profile})`,
          backgroundSize: "cover",
          backgroundPosition: "center top",
        }}
      >
        <span className="mask bg-gradient-info opacity-1" />

        <Container className="d-flex align-items-center" fluid>
          <Row>
            <Col lg="12">
              <h1 className="display-2 text-white">{title}</h1>
              {
                {
                  'list': <Button
                    className="btn-info"
                    color="default"
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();

                      // Set ETL job
                      dispatch({
                        type: SET_ETL_JOB,
                        payload: null
                      });

                      // Set process state
                      dispatch({
                        type: UPDATE_PROCESS_STAGE,
                        payload: {
                          stage: 'upload'
                        }
                      });

                      // Go to process
                      history.push("/admin/offers/etl/create");
                    }}
                  >
                    <span className="btn-inner--icon mr-2">
                      <i className="ni ni-money-coins" />
                    </span>
                    <span className="btn-inner--text">Process New Offers</span>
                  </Button>,
                  'upload': <Button
                    color="default"
                    href=""
                    size="xl"
                    onClick={() => {
                      // Go to sent
                      history.push("/admin/offers/etl");
                    }}
                  >
                    <span className="btn-inner--icon mr-2">
                      <i className="ni ni-bullet-list-67" />
                    </span>
                    <span className="btn-inner--text">View Processed Offers</span>
                  </Button>,
                  'review': <Button
                    color="default"
                    href=""
                    size="xl"
                    onClick={() => {
                      // Go to sent
                      history.push("/admin/offers/etl");
                    }}
                  >
                    <span className="btn-inner--icon mr-2">
                      <i className="ni ni-bullet-list-67" />
                    </span>
                    <span className="btn-inner--text">View Processed Offers</span>
                  </Button>
                }[stage]
              }
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

// Connect to store
const ConnectedOffersHeader = connect(state => ({
  profile: state.profile
}))(OffersHeader);

export default ConnectedOffersHeader;
