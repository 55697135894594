/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Imports
import Sample from "views/pages/Sample.js";

// Auth
import Login from "views/auth/Login.js";
import Register from "views/auth/Register.js";

// Offers
import Home from "views/offers/home";
import ETLList from "views/offers/etl/list";
import ETLCreate from "views/offers/etl/create";
import SnapshotsCreate from "views/offers/reporting/create";
import SnaphotsList from "views/offers/reporting/list";

const routes = [
  {
    collapse: true,
    name: "Offers",
    display: true,
    icon: "ni ni-diamond text-primary",
    state: "offersCollapse",
    views: [
      {
        path: "/home",
        display: true,
        name: "Home",
        miniName: "C",
        component: Home,
        layout: "/admin",
      },
      {
        path: "/offers/etl/create",
        display: false,
        component: ETLCreate,
        layout: "/admin",
      },
      {
        path: "/offers/etl",
        display: true,
        name: "Daily Uploads",
        miniName: "S",
        component: ETLList,
        layout: "/admin",
      }
    ],
  },
  {
    collapse: true,
    name: "Examples",
    display: false,
    icon: "ni ni-ungroup text-orange",
    state: "examplesCollapse",
    views: [
      {
        path: "/login",
        display: false,
        name: "Login",
        miniName: "L",
        component: Login,
        layout: "/auth",
      }
    ],
  },
];

export default routes;
