export const version = "1.0.0";

export const accountId = "kentucky-sandys-gaming";

export const env = "prod";

export const firebaseConfig = {
  apiKey: "AIzaSyD4SX53tNChyzko_WBgdiozMZ2u8_bwNmg",
  authDomain: "kentucky-sandys-gaming-prod.firebaseapp.com",
  projectId: "kentucky-sandys-gaming-prod",
  storageBucket: "kentucky-sandys-gaming-prod.appspot.com",
  messagingSenderId: "916029337661",
  appId: "1:916029337661:web:81adabad7aa3012f90fa54"
};

export const reduxFirebase = {
  enableLogging: "false",
};

export const atlas = {
  serverUrl: "http://localhost:5001/axon-platform/us-central1",
};

export const build = {
  baseUrl: "http://localhost:3100",
};

export const sdk = {
  baseUrl: "https://localhost:9000",
};

export const configs = {
  baseUrl: "https://axon-config.ngrok.io",
};

export const customer = {
  activityStreamTopicName: "customer-activity",
};

export const agent = {
  ipStackAPIAccessKey: "4d1a98985ea36c7d938ee42f111441bf",
};

export default { version, accountId, env, firebaseConfig, reduxFirebase, atlas, build, sdk, configs, customer, agent };
